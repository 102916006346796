angular.module('enervexSalesappApp').controller('FixWarningsModalCtrl', function($scope, $uibModalInstance, Auto, setValue, DoUndo, Util, context, paint, ProductSubtype, FittingMetaUtil, Product, SelectionMetaUtil, LengthUtil, Job, JobService, _) {
	$scope.showClose = true;
	$scope.showApply = false;

	$scope.changedFix = function(message, fix) {
		if (fix.selected) {
			_.each(message.fixes, function(f){
				if (f != fix) {
					f.selected = false
				}
			})
		}
		checkShowApply()
	}
	$scope.cancelFixes = function() {
		var modalInstance = $uibModalInstance.close(null);
	}
	var fixValdiationMessages = $scope.validationManager.toFixMessages()
	if (fixValdiationMessages && fixValdiationMessages.length > 0) {
		$scope.fixMessages = fixValdiationMessages
	} else {
		var fixBomMessages = _.filter($scope.design.bomResponse.messages, function(m){
			return m.fixes && m.fixes.length > 0
		})
		//now reduce
		var reducedFixMessages = []
		_.each(fixBomMessages, function(message){
			if (message.canAggregate) {
				var existing = _.find(reducedFixMessages, function(o){
					return o != message && o.shortMessage == message.shortMessage
				})
				if (existing) {
					console.log("reducing "+ message.shortMessage)
					existing.faultItems = existing.faultItems.concat([message.faultItem])
				} else {
					message.faultItems = [message.faultItem]
					reducedFixMessages.push(message)
				}
			} else {
				message.faultItems = [message.faultItem]
				message.faultItem = message.faultItem
				reducedFixMessages.push(message)
			}
		})
		$scope.fixMessages = fixValdiationMessages.concat(reducedFixMessages)
	}
	var fixIndex = 0
	_.each($scope.fixMessages, function(message){
		_.each(message.fixes, function(fix){
			fixIndex = fixIndex + 1
			fix._id = fixIndex
			fix.selected = false
		})
	})
	function checkShowApply() {
		var selected = _.find($scope.fixMessages, function(m){
			return _.find(m.fixes, function(f){
				return f.selected
			})
		})
		if (selected) {
			$scope.showApply = true
		} else {
			$scope.showApply = false
		}
	}
	function roundToNearest(n, multiple) {
	    return Math.round(n / multiple) * multiple;
	}

	function splitLine(line, increaser) {
	    // Extract coordinates from the original line
	    var X1 = line.X1, 
	    	Y1 = line.Y1,
	    	Z1 = line.Z1,
	    	X2 = line.X2,
	    	Y2 = line.Y2,
	    	Z2 = line.Z2;
	    
	    // Calculate the point 25% along the line using a parameter 't'
	    var t = 0.5;
	    if (increaser) {
	    	t = 0.5
	    }
	    var splitX = X1 + t * (X2 - X1);
	    var splitY = Y1 + t * (Y2 - Y1);
	    var splitZ = Z1 + t * (Z2 - Z1);
	    
	    // Round the coordinates to the nearest 50
	    splitX = roundToNearest(splitX, 25);
	    splitY = roundToNearest(splitY, 25);
	    splitZ = roundToNearest(splitZ, 25);
	    
	    // Create new line segments
	    var line1 = {
	        X1: X1,
	        Y1: Y1,
	        Z1: Z1,
	        X2: splitX,
	        Y2: splitY,
	        Z2: splitZ
	    };
	    
	    var line2 = {
	        X1: splitX,
	        Y1: splitY,
	        Z1: splitZ,
	        X2: X2,
	        Y2: Y2,
	        Z2: Z2
	    };
	    
	    return [line1, line2];
	}
	function insertFromTo(changeSection, newDiamater, fromInline) {
		if (fromInline) {
			return insertFrom(changeSection, newDiamater)
		} else {
			return insertTo(changeSection, newDiamater)
		}
	}
	function insertFrom(changeSection, decreaserDiameter) {
		if (changeSection) {
			var originalSection = _.cloneDeep(changeSection)
			var splits = splitLine(changeSection);
			var line1 = splits[0],
				line2 = splits[1];
			var props = ["X1", "X2", "Y1", "Y2", "Z1", "Z2", "DIMX", "DIMY"]
			_.each(props, function(key) {
				changeSection[key] = line1[key]
			})

			changeSection.Fit2 = ''
			var firstBreak = false;
			var newSection = Util.createSectionFromBreak(line2.X1, line2.Y1, line2.X2, line2.Y2, line2.Z1, line2.Z2, $scope.design, originalSection, firstBreak)

			newSection.DimX = changeSection.DimX/2
			changeSection.DimX = changeSection.DimX/2
			newSection.DimY = changeSection.DimY/2
			changeSection.DimY = changeSection.DimY/2
			newSection.DimZ = changeSection.DimZ/2
			changeSection.DimZ = changeSection.DimZ/2
	
			changeSection.Dim1 = decreaserDiameter
			changeSection.Optimize = 'No'
			changeSection.INLTransition = true
			$scope.design.fc5layout.Layout.push(newSection)
			paint()
		}
	}
	function insertTo(changeSection, increaserDiameter) {
		if (changeSection) {
			var originalSection = _.cloneDeep(changeSection)
			var splits = splitLine(changeSection, true);
			var line1 = splits[0],
				line2 = splits[1];
			var props = ["X1", "X2", "Y1", "Y2", "Z1", "z2"]
			_.each(props, function(key) {
				changeSection[key] = line1[key]
			})

			changeSection.Fit2 = ''
			var firstBreak = false;
			var newSection = Util.createSectionFromBreak(line2.X1, line2.Y1, line2.X2, line2.Y2, line2.Z1, line2.Z2, $scope.design, originalSection, firstBreak)

			newSection.DimX = changeSection.DimX/2
			changeSection.DimX = changeSection.DimX/2
			newSection.DimY = changeSection.DimY/2
			changeSection.DimY = changeSection.DimY/2
			newSection.DimZ = changeSection.DimZ/2
			changeSection.DimZ = changeSection.DimZ/2

			newSection.Dim1 = increaserDiameter
			newSection.Optimize = 'No'
			newSection.INLTransition = true
			$scope.design.fc5layout.Layout.push(newSection)
			paint()	
		}
	}
	function applyFaultItem(v, fix) {
		if (fix.type == "decreaserNeeded") {
			insertFromTo(v, fix.decreaserDiameter, fix.fromInline)
		} else if (fix.type == "increaserNeeded") {
			insertFromTo(v, fix.increaserDiameter, fix.fromInline)
		} else if (fix.type == "setLength") {
			v.Length = parseFloat(fix.setLength)
		} else if (fix.type == "upSlope") {
			var dimY = LengthUtil.get3DegreeLength(v, $scope.design)
			v.DimY = dimY
			console.log("set slope to "+v.DimY + " on "+v.GUIidentPath)
		} else if (fix.type == "downSlope") {
			var dimY = LengthUtil.get3DegreeLength(v, $scope.design)
			v.DimY = dimY * -1
		} else if (fix.type == "downSlope") {
			var dimY = LengthUtil.get3DegreeLength(v, $scope.design)
			v.DimY = dimY * -1
		} else if (fix.type == "setDiameterOptimize") {
			v.Dim1 = fix.diameter
			v.Optimize = "No"
		} else if (fix.type == "setSection") {
			var fittingMeta = FittingMetaUtil.getFittingMeta(v, $scope.design, $scope.fittings, "applyFaultItem")
			var centerpoint = fittingMeta[fix.fixCenterpoint]
			centerpoint.type = "section"
			centerpoint.GUIidentPath = fix.fixGUIidentPath
			centerpoint.ID = fix.fixID
		} else if (fix.type == "setSawtoothCount") {
			var selectionMeta = SelectionMetaUtil.getSelectionMeta(v, $scope.design)
			var sawtoothCount = fix.sawtoothCount
			selectionMeta.sawtoothCount = sawtoothCount
		} else if (fix.type == "cancelSawtooth") {
			var selectionMeta = SelectionMetaUtil.getSelectionMeta(v, $scope.design)
			selectionMeta.sawtooth = false
		} else if (fix.type == "variableMode") {
			$scope.design.stack.variableMode = fix.variableMode
		} else if (fix.type == "setJobZip") {
			var zip = fix.zip
			zip = zip ? zip.trim() : ''
			if (zip == '') {
				throw({
					message: 'Zip must be a valid value'
				})
			}
			//now set and update job
			if (!$scope.job.address) {
				$scope.job.address = {}
			}
			$scope.job.address.zip = zip
			JobService.updateJob($scope.job).$promise.then(function(res){
				$scope.job = res
			})
		}

	}
	$scope.apply = function() {
		try {
			_.each($scope.fixMessages, function(message){
				_.each(message.fixes, function(fix){
					if (fix.selected) {
						if (fix.type == 'setJobZip' || fix.type == 'variableMode') {
							applyFaultItem(null, fix)
						} else if (message.canAggregate) {
							_.each(message.faultItems, function(faultItem){
								var v = _.find($scope.design.fc5layout.Layout, function(v){
									return faultItem.GUIidentPath == v.GUIidentPath
								})
								if (v) {
									applyFaultItem(v, fix)
								}
							})
						} else if (message.faultItem && message.faultItem.GUIidentPath) {
							var v = _.find($scope.design.fc5layout.Layout, function(v){
								return message.faultItem.GUIidentPath == v.GUIidentPath
							})
							if (v) {
								applyFaultItem(v, fix)
							}
						} else {
							throw new Error('could not apply fix "' + fix.message + '"')
						}
					}
					return true
				})
				return true
			})
			//now compute
			console.log("set props and then compute")
			var modalInstance = $uibModalInstance.close(true);
		} catch(e) {
			var msg = (e.message) ? e.message : ""+e
			console.log("error", e)
			alert(msg)
			return false
		}
		return true;
	}
	checkShowApply()
});
